import * as React from "react";
import {useI18next} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";

export default function Home({data}) {
  const {navigate, language} = useI18next();
  if (data.intro) {
    const articles = data.intro.edges
        .filter((article) => article.node.locale === language);
    if (articles.length) {
      navigate(`/article/${articles[0].node.slug}`);
    } else {
      navigate("/app/dashboard");
    }
  } else {
    navigate("/app/dashboard");
  }
  return null;
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale (filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    intro: allStrapiArticle(filter: {order: {eq: 0}}) {
      edges {
        node {
          slug
          locale
        }
      }
    }
  }
`;
